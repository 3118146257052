import { AgriculturalProductMeasurers, LoanAction, LoanStatus, LoanType, ScorecardLevel } from "../shared/Enums";
import SelectOption from "../shared/models/selectOption"
import Applicant from "models/applicantForm/applicant";
import { format } from "date-fns";
import NAICSCode from "models/operatingLoan/naicsCode";
import { getYear } from "date-fns/esm";

class LoanApplicationUtility {
    public GetLoanTypeText(loanType: number): string {
        switch (loanType) {
            case LoanType.PurchaseFixedAsset:
                return 'Purchase of Fixed Asset';
            case LoanType.RefinanceDebt:
                return 'Refinance of Debt';
            case LoanType.OperatingLoan:
                return 'Operating Loan';
            case LoanType.DealerPurchase:
                return 'Dealer Purchase';
            default:
                return '';
        }
    }

    public GetLoanStatusText(loanType: number): string {
        switch (loanType) {
            case LoanStatus.Submitted:
                return 'Submitted';
            case LoanStatus.Processing:
                return 'Processing';
            case LoanStatus.NeedsInput:
                return 'More Information Needed';
            case LoanStatus.Prequalified:
                return 'Pre-Qualified';
            case LoanStatus.OfferSent:
                return 'Pre-Qualification Offer Sent';
            case LoanStatus.PrequalOfferAccepted:
                return 'Pre-Qualification Offer Accepted';
            case LoanStatus.PrequalOfferRejected:
                return 'Pre-Qualification Offer Rejected';
            case LoanStatus.Approved:
                return 'Approved By Bank';
            case LoanStatus.DocumentsSent:
                return 'Documents Sent';
            case LoanStatus.ConditionsMet:
                return 'Conditions Met';
            case LoanStatus.Withdrawn:
                return 'Withdrawn';
            case LoanStatus.PrequalDenied:
                return 'Denied (Pre-Qualification)';
            case LoanStatus.BankDenied:
                return 'Denied (Bank)';
            default:
                return '';
        }
    }

    public GetLoanScorecardLevel(level: number): string {
        switch (level) {
            case ScorecardLevel.Level1:
                return 'Level 1';
            case ScorecardLevel.Level2:
                return 'Level 2';
            case ScorecardLevel.Level3:
                return 'Level 3';
            default:
                return '';
        }
    }

    public GetLoanTypeOptions(): SelectOption[] {
        return [
            {
                value: LoanType.PurchaseFixedAsset,
                label: 'Purchase of Fixed Assets'
            } as SelectOption,
            {
                value: LoanType.RefinanceDebt,
                label: 'Refinance of Debt'
            } as SelectOption,
            {
                value: LoanType.OperatingLoan,
                label: 'Operating Loan'
            } as SelectOption,
            {
                value: LoanType.DealerPurchase,
                label: 'Dealer Purchase'
            } as SelectOption
        ];
    }

    public GetLoanScorecardLevelOptions(): SelectOption[] {
        return [
            {
                value: ScorecardLevel.Level1,
                label: 'Level 1'
            } as SelectOption,
            {
                value: ScorecardLevel.Level2,
                label: 'Level 2'
            } as SelectOption,
            {
                value: ScorecardLevel.Level3,
                label: 'Level 3'
            } as SelectOption
        ];
    }

    public GetActiveLoanStatusOptions(): SelectOption[] {
        return [
            {
                value: LoanStatus.Submitted,
                label: 'Submitted'
            } as SelectOption,
            {
                value: LoanStatus.Processing,
                label: 'Processing'
            } as SelectOption,
            {
                value: LoanStatus.NeedsInput,
                label: 'More Information Needed'
            } as SelectOption,
            {
                value: LoanStatus.Prequalified,
                label: 'Pre-Qualified'
            } as SelectOption,
            {
                value: LoanStatus.OfferSent,
                label: 'Pre-Qualification Offer Sent'
            } as SelectOption,
            {
                value: LoanStatus.PrequalOfferAccepted,
                label: 'Pre-Qualification Offer Accepted'
            } as SelectOption,
            {
                value: LoanStatus.PrequalOfferRejected,
                label: 'Pre-Qualification Offer Rejected'
            } as SelectOption,
            {
                value: LoanStatus.Approved,
                label: 'Bank Approved'
            } as SelectOption,
            {
                value: LoanStatus.DocumentsSent,
                label: 'Documents Sent'
            } as SelectOption
        ];
    }

    public GetClosedLoanStatusOptions(): SelectOption[] {
        return [
            {
                value: LoanStatus.ConditionsMet,
                label: 'Conditions Met'
            } as SelectOption,
            {
                value: LoanStatus.PrequalDenied,
                label: 'Denied (Pre-Qualification)'
            } as SelectOption,
            {
                value: LoanStatus.BankDenied,
                label: 'Denied (Bank)'
            } as SelectOption,
            {
                value: LoanStatus.Withdrawn,
                label: 'Withdrawn'
            } as SelectOption
        ];
    }

    public GetActiveLoanStatuses(): number[] {
        return [LoanStatus.Submitted, LoanStatus.Processing, LoanStatus.NeedsInput, LoanStatus.Prequalified, LoanStatus.OfferSent, LoanStatus.PrequalOfferAccepted, LoanStatus.PrequalOfferRejected, LoanStatus.Approved, LoanStatus.DocumentsSent];
    }

    public GetClosedLoanStatuses(): number[] {
        return [LoanStatus.ConditionsMet, LoanStatus.BankDenied, LoanStatus.PrequalDenied, LoanStatus.Withdrawn];
    }

    public GetLoanActionOptions(loanStatus: number): SelectOption[] {
        // This will get expanded as we implement features down the road
        let options: SelectOption[] = [];

        if (this.GetActiveLoanStatuses().includes(loanStatus)) {
            options.push(...[
                {
                    value: LoanAction.RequestInput,
                    label: 'Request Input'
                } as SelectOption,
                {
                    value: LoanAction.Prequalify,
                    label: 'Mark Pre-Qualified'
                } as SelectOption,
                {
                    value: LoanAction.Approve,
                    label: 'Mark Approved (By Bank)'
                } as SelectOption,
                {
                    value: LoanAction.DocumentsSent,
                    label: 'Notify Documents Sent'
                } as SelectOption,
                {
                    value: LoanAction.ConditionsMet,
                    label: 'Conditions Met'
                } as SelectOption,
                {
                    value: LoanAction.Withdraw,
                    label: 'Withdraw'
                } as SelectOption,
                {
                    value: LoanAction.PrequalDeny,
                    label: 'Deny Loan (Pre-Qualification)'
                } as SelectOption,
                {
                    value: LoanAction.BankDeny,
                    label: 'Deny Loan (Bank Denial)'
                } as SelectOption,
            ])
        }

        // Saving below for future reference
        // For now we will just show all options
        // Checking first here to add as first option
        // if (this.GetActiveLoanStatuses().includes(loanStatus)) {
        //     options.push(
        //         {
        //             value: LoanAction.RequestInput,
        //             label: 'Request Input'
        //         } as SelectOption,
        //     );
        // }

        // if ([LoanStatus.Submitted, LoanStatus.Processing, LoanStatus.NeedsInput].includes(loanStatus)) {
        //     options.push(...[
        //         {
        //             value: LoanAction.Approve,
        //             label: 'Approve Loan'
        //         } as SelectOption,
        //     ]);
        // }

        // if (this.GetActiveLoanStatuses().includes(loanStatus)) {
        //     options.push(...[
        //         {
        //             value: LoanAction.BankDeny,
        //             label: 'Deny Loan'
        //         } as SelectOption,
        //         {
        //             value: LoanAction.ConditionsMet,
        //             label: 'Conditions Met'
        //         } as SelectOption,
        //     ])
        // }

        return options;
    }

    public GetApplicantName(applicant: Applicant): string {
        return `${applicant.firstName} ${applicant.lastName}${applicant.suffix !== undefined ? ' ' + applicant.suffix : ''}`;
    }

    public JoinApplicantNames(applicants: Applicant[]): string {
        return applicants.map((a: Applicant) => this.GetApplicantName(a)).join(', ');
    }

    public FormatPhoneNumer(phoneNumber: string): string {
        return `(${phoneNumber.toString().slice(0, 3)}) ${phoneNumber.toString().slice(3, 6)}-${phoneNumber.toString().slice(6, 10)}`;
    }

    public GetDateText(date: Date | string): string {
        return `${format(new Date(date), 'M/d/yy')}`;
    }

    public GetFullYearDateText(date: Date | string): string {
        return `${format(new Date(date), 'M/d/yyyy')}`;
    }

    public GetDateMonthNameFullYear(date: Date | string): string {
        return `${format(new Date(date), 'MMMM d, yyyy')}`
    }

    public BuildAmount(value?: number): string {
        return `${value?.toLocaleString() ?? '-'}`;
    }

    public BuildCurrencyAmount(value?: number): string {
        return `$${value?.toLocaleString(undefined, { minimumFractionDigits: 2 }) ?? '-'}`;
    }

    public BuildDisplayId(id: number): string {
        var displayId = id.toString();
        var totalNumberOfZerosNeeded = 6 - displayId.length
        for (var i = 0; i < totalNumberOfZerosNeeded; i++) {
            displayId = '0' + displayId;
        }
        return '#' + displayId;
    }

    public GetAgriculturalProductMeasurers(measurement: AgriculturalProductMeasurers): string {
        switch (measurement) {
            case AgriculturalProductMeasurers.Acres:
                return 'Acre';
            case AgriculturalProductMeasurers.Bale:
                return 'Bale';
            case AgriculturalProductMeasurers.Bushels:
                return 'Bushel';
            case AgriculturalProductMeasurers.CwtPer100Pounds:
                return 'CwtPer100Pounds';
            case AgriculturalProductMeasurers.Flat:
                return 'Flat';
            case AgriculturalProductMeasurers.FourFiveBushel:
                return 'FourFiveBushel';
            case AgriculturalProductMeasurers.Gallon:
                return 'Gallon';
            case AgriculturalProductMeasurers.Hogshead:
                return 'Hogshead';
            case AgriculturalProductMeasurers.Lug:
                return 'Lug';
            case AgriculturalProductMeasurers.Other:
                return 'Other';
            case AgriculturalProductMeasurers.PerAcre:
                return 'Per Acre';
            case AgriculturalProductMeasurers.PerBoardFoot:
                return 'Per Board Foot';
            case AgriculturalProductMeasurers.PerHead:
                return 'Per Head';
            case AgriculturalProductMeasurers.Pound:
                return 'Pound';
            case AgriculturalProductMeasurers.Sacks:
                return 'Stack';
            case AgriculturalProductMeasurers.ThreeFourBushel:
                return 'ThreeFourBushel';
            case AgriculturalProductMeasurers.Tons:
                return 'Ton';
            default:
                return ''
        }
    }

    public BuildOtherTitle(naicsCode: NAICSCode): string {
        return `${naicsCode.title}${naicsCode.otherTitle?.length > 0 ? ` - ${naicsCode.otherTitle}` : ''}`;
    }

    public GetYearsExperience(farmingStartYear: number): string {
        let yearsExperience: number = getYear(new Date()) - farmingStartYear;
        if (yearsExperience === 0) yearsExperience = 1;
        return `${yearsExperience} year(s)`;
    }
}

const loanApplicationUtility = new LoanApplicationUtility();
export default loanApplicationUtility;