import React from "react";
import loanApplicationUtility from "../../../utilities/LoanApplicationUtility";
import Table from "react-bootstrap/esm/Table";
import PagedList from "../../../models/accessors/pagedList";
import LoanApplication from "../../../models/loan/loanApplication";
import "./LoansListResults.css"
import bankEmployeeUtility from "../../../utilities/BankEmployeeUtility";
import Can from "auth/Can";

interface LoansListResultsProps {
    pagedLoanApplications: PagedList<LoanApplication>;
    route: any;
    noResultsText: string;
}

export default class LoansListResults extends React.Component<LoansListResultsProps> {
    render() {
        return (
            <div>
                {this.props.pagedLoanApplications.items && this.props.pagedLoanApplications.items.length > 0 ?
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Date Submitted</th>
                                <th>Loan Amount</th>
                                <th>Reference #</th>
                                <Can
                                    perform="Loan Officer"
                                    yes={() => (
                                        <th>Loan Purpose</th>
                                    )}
                                    no={() => <></>}
                                />
                                <th>Submitted By</th>
                                <th>Loan Officer</th>
                                <Can
                                    perform="Loan Officer"
                                    yes={() => (
                                        <th>Scorecard Level</th>
                                    )}
                                    no={() => <></>}
                                />
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.pagedLoanApplications.items.map((la: LoanApplication, index: number) => {
                                return (
                                    <tr key={`loans-list-${index.toString()}`} className="loans-list-item-row" onClick={() => this.props.route.history.push(`/loan/${la.id}`)}>
                                        <td>{loanApplicationUtility.GetDateMonthNameFullYear(la.dateSubmitted)}</td>
                                        <td>{loanApplicationUtility.BuildCurrencyAmount(la.loanAmount)}</td>
                                        <td>{loanApplicationUtility.BuildDisplayId(la.id)}</td>
                                        <Can
                                            perform="Loan Officer"
                                            yes={() => (
                                                <td>{loanApplicationUtility.GetLoanTypeText(la.loanType)}</td>
                                            )}
                                            no={() => <></>}
                                        />
                                        <td>{loanApplicationUtility.JoinApplicantNames(la.applicants)}</td>
                                        <td>{la.bankEmployee !== undefined ? bankEmployeeUtility.GetBankEmployeeName(la.bankEmployee) : '--'}</td>
                                        <Can
                                            perform="Loan Officer"
                                            yes={() => (
                                                <td>{loanApplicationUtility.GetLoanScorecardLevel(la.scorecardLevel)}</td>
                                            )}
                                            no={() => <></>}
                                        />
                                        <td>{loanApplicationUtility.GetLoanStatusText(la.status)}</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </Table>
                    :
                    <h3>{this.props.noResultsText}</h3>
                }
            </div>
        )
    }
}